import { PostItem } from "./PostItem";

// posts
//
export const PostList = ({ posts }) => {
  return (
    <div id="post--list_all" className="row">
      {posts.map((post) => (
        <PostItem post={post} key={post.id} />
      ))}
    </div>
  );
};
