import { PrismicRichText, PrismicText } from "@prismicio/react";

// split image text slice
//
export const SplitImageText = ({ slice }) => (
  <section className="content-slice component--split_image">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <img className="img-fluid" src={slice.primary.image.url} alt={slice.primary.image.alt} />
        </div>
        <div className="col-md-6">
          <div className="colored--bg">
            <h3 className="smono mb-5">
              <PrismicText field={slice.primary.heading} />
            </h3>
            <PrismicRichText field={slice.primary.copy} />
          </div>
        </div>
      </div>
    </div>
  </section>
);