import { useEffect } from "react";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicLink, PrismicRichText } from "@prismicio/react";
import Masonry from 'react-masonry-css'


import { Layout } from "../components/Layout";
import { HomepageBanner } from "../components/HomepageBanner";
import { PostList } from "../components/PostList";
import { CaseList } from "../components/CaseList";
import { PeopleList } from "../components/PeopleList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// homepage component
//
export const HomePage = () => {
  const [home, homeState] = useSinglePrismicDocument("homepage");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {
    orderings: [{ field: "my.post.post_date", direction: "desc" }],
    pageSize: 3,
  });
  const [caseStudies, caseStudiesState] = usePrismicDocumentsByType("case_study", {
    orderings: [{ field: "my.case_study.date", direction: "desc" }],
    pageSize: 9,
  });
  const [people, peopleState] = usePrismicDocumentsByType("people", {
    orderings: [{ field: "my.people.name", direction: "desc" }],
    pageSize: 50,
  });

  const notFound = homeState.state === "failed" || menuState.state === "failed" || blogPostsState.state === "failed" || caseStudiesState.state === "failed" || peopleState.state === "failed";

  useEffect(() => {
    if (homeState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [homeState.state]);

  // approach values
  const AppValues =({ appVal }) => {
    return (
      <div>
        <img className="img-fluid" src={appVal.image.url} alt={appVal.image.alt} />
        <p
          className="value smono"
          style={{
            backgroundImage: `url(${appVal.icon.url})`,
          }}
        >
          <PrismicText field={appVal.value}></PrismicText>
        </p>
      </div>
    );
  };

  // expertise nav
  const ExNav =({ expertiseNav }) => {
    return (
      <li role="presentation" className="mb-2">
        <button className={`nav-link h4 ${((expertiseNav.expertise_classes || {})[0] || {}).text}`} id={`pills-${((expertiseNav.expertise_identifier || {})[0] || {}).text}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${((expertiseNav.expertise_identifier || {})[0] || {}).text}`} type="button" role="tab" aria-controls={`pills-${((expertiseNav.expertise_identifier || {})[0] || {}).text}`} aria-selected="false" style={{backgroundImage: `url(${expertiseNav.icon.url})`,}}>
          <PrismicText field={expertiseNav.expertise_title} />
        </button>
      </li>
    );
  };

  // expertise imgs
  const ExImgs =({ expertiseImg }) => {
    return (
      <div className={`tab-pane fade smono ${((expertiseImg.expertise_classes || {})[0] || {}).text}`} id={`pills-${((expertiseImg.expertise_identifier || {})[0] || {}).text}`} role="tabpanel" aria-labelledby={`pills-${((expertiseImg.expertise_identifier || {})[0] || {}).text}-tab`} tabIndex="0">
        <img className="img-fluid mb-3" src={expertiseImg.image.url} alt={expertiseImg.image.alt} />
        <PrismicRichText field={expertiseImg.expertise_description} />
      </div>
    );
  };

  // client logos
  const Logos =({ clientLogo }) => {
    return(
      <img className="img-fluid" src={clientLogo.logo.url} alt={clientLogo.logo.alt} />
    );
  };

  // masonry layout
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2,
    500: 1
  };

  // randomize team members
  const shuffleTeam = () => {
    setTimeout(() => {
      let team = document.querySelector('#team');
      for (var i = team.children.length; i >= 0; i--) {
        team.appendChild(team.children[Math.random() * i | 0]);
      }
    }, 1000);
  }

  // randomize case studies
  const shuffleWork = () => {
    setTimeout(() => {
      let work = document.querySelector('#case--study_list');
      for (var i = work.children.length; i >= 0; i--) {
        work.appendChild(work.children[Math.random() * i | 0]);
      }
    }, 1000);
  }

  // Return the page if a document was retrieved from Prismic
  //
  if (home && menu && blogPosts?.results && caseStudies?.results && people?.results) {
    return (
      <Layout wrapperClass="homepage" menuDoc={menu}>
        <Helmet>
          <title data-react-helmet="true">{((home.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((home.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://launchsquad.com`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((home.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((home.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={home.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://launchsquad.com"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((home.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((home.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={home.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((home.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((home.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={home.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <HomepageBanner banner={home.data.homepage_banner[0]} />

        <hr />

        <section className="approach">
          <div className="container py-5">
            <div className="row py-5">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="approach--values"
                columnClassName="approach--values_column"
              >
                <div>
                  <h3 className="smono mb-5">
                    <PrismicText field={home.data.approach_header}></PrismicText>
                  </h3>
                  <p>
                    <PrismicText field={home.data.approach_copy}></PrismicText>
                  </p>
                </div>
                {home.data.approach_values.map((appVal) => (
                  <AppValues appVal={appVal} key={appVal.image.url} />
                ))}
              </Masonry>
            </div>
          </div>
        </section>

        <section className="what-we-do">
          <div className="container py-5">
            <div className="row pt-5">
              <h3 className="smono mb-5">
                <PrismicText field={home.data.expertise_header}></PrismicText>
              </h3>
            </div>
            <div className="row pb-5">
              <div className="col-md-5">
                <div className="tab-content" id="pills-tabContent" data-device="desktop">
                  {home.data.expertise.map((expertiseImg) => (
                    <ExImgs expertiseImg={expertiseImg} key={expertiseImg.image.url} />
                  ))}
                </div>
              </div>
              <div className="col-md-7">
                <ul className="nav nav-pills flex-column mb-3" id="expertise-tab" role="tablist">
                  {home.data.expertise.map((expertiseNav) => (
                    <ExNav expertiseNav={expertiseNav} key={((expertiseNav.expertise_identifier || {})[0] || {}).text} />
                  ))}
                </ul>
                <PrismicLink field={home.data.expertise_link} className="btn btn-primary mt-3">
                  <PrismicText field={home.data.expertise_link_label} />
                </PrismicLink>
              </div>
            </div>
          </div>
        </section>

        <section className="about-us">
          <div className="container py-5">
            <div className="row py-5">
              <div className="col-md-4">
                <h3 className="smono mb-5">
                  <PrismicText field={home.data.about_us_header} />
                </h3>
              </div>
              <div className="col-md-8">
                <h4>
                  <img className="inline--icon" src={home.data.about_icon.url} alt={home.data.about_icon_alt} />
                  <PrismicText field={home.data.about_lead_in} />
                </h4>
              </div>
            </div>
            <div className="row">
              <PeopleList peoples={people?.results} />
              {shuffleTeam()}
            </div>
            <div className="row mt-5 pb-5">
              <div className="col text-center">
                <PrismicLink field={home.data.about_link} className="btn btn-primary mt-3">
                  <PrismicText field={home.data.about_label} />
                </PrismicLink>
              </div>
            </div>
          </div>
        </section>

        <section className="latest-work">
          <div className="container py-5">
            <div className="row py-5">
              <div className="col-md-8">
                <h3 className="smono mb-5">
                  <PrismicText field={home.data.work_header} />
                </h3>
                <PrismicRichText field={home.data.work_lead_in} />
              </div>
            </div>
            <div className="row">
              <CaseList casestudies={caseStudies?.results} />
              {shuffleWork()}
            </div>
            <div className="row">
              <div className="col text-center pb-3">
                <PrismicLink field={home.data.work_link} className="btn btn-primary mt-3">
                  <PrismicText field={home.data.work_link_label} />
                </PrismicLink>
              </div>
            </div>
          </div>
          <hr />
          <div className="container-fluid py-5">
            <div className="row">
              <div className="col marquee--wrapper">
                <div className="marquee--left"></div>
                <div className="marquee--right"></div>
                <div className="marquee--slider">
                  {home.data.client_logos.map((clientLogo) => (
                    <Logos clientLogo={clientLogo} key={clientLogo.url} />
                  ))}
                  {home.data.client_logos.map((clientLogo) => (
                    <Logos clientLogo={clientLogo} key={clientLogo.url} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-spotlight">
          <div className="container py-5">
            <div className="row py-5">
              <div className="col-md-8">
                <h3 className="smono mb-5">
                  <PrismicText field={home.data.blog_header} />
                </h3>
                <h4
                  className="sec--header"
                  style={{
                    backgroundImage: `url(${home.data.blog_icon.url})`,
                  }}
                >
                  <PrismicText field={home.data.blog_lead_in} />
                </h4>
              </div>
            </div>
            <div className="row">
              <PostList posts={blogPosts?.results} />
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};