import { useEffect } from "react";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { CaseList } from "../components/CaseList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// work landing page
//
export const Work = () => {
  const [work, workState] = useSinglePrismicDocument("work");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [caseStudies, caseStudiesState] = usePrismicDocumentsByType("case_study", {
    orderings: [{ field: "my.case_study.date", direction: "desc" }],
    pageSize: 100,
  });

  const notFound = workState.state === "failed" || menuState.state === "failed" || caseStudiesState.state == "failed";

  useEffect(() => {
    if (workState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [workState.state]);

  // filter work
  function filterWork() {
    document.querySelectorAll('.nav-item_filter').forEach(filter => {
      filter.addEventListener('click', e => {

        // get target filter
        let target = e.target;
        let targetFilter = target.getAttribute('data-filter');

        // filter nav updates
        document.querySelectorAll('.nav-item_filter').forEach(activeFilter => {
          activeFilter.classList.remove('active');
        })
        // active filter class
        target.classList.add('active');

        if ( targetFilter == 'All' ) {
          // show all
          let filterCats = document.querySelectorAll('.cat--desc');
          filterCats.forEach(cat => {
            cat.classList.remove('is--visible');
          })
          let allCases = document.getElementById('work--list').querySelectorAll('.case--study');
          allCases.forEach(caseDetail => {
            caseDetail.classList.remove('filtering');
            caseDetail.classList.remove('is--filtered');
          })
        } else {
          // hide all to start
          let hideCats = document.querySelectorAll('.cat--desc');
          hideCats.forEach(cat => {
            cat.classList.remove('is--visible');
          })
          let hideCases = document.getElementById('work--list').querySelectorAll('.case--study');
          hideCases.forEach(caseLoad => {
            caseLoad.classList.add('filtering');
            caseLoad.classList.remove('is--filtered');
          })
          // show filtered
          // main filter
          let filteredCase = document.getElementById('work--list').querySelectorAll('.case--study[data-category="' + targetFilter + '"]');
          // alt filter
          let filteredAltCase = document.getElementById('work--list').querySelectorAll('.case--study[data-alt-category="' + targetFilter + '"]');
          let filteredAlt2Case = document.getElementById('work--list').querySelectorAll('.case--study[data-alt-2-category="' + targetFilter + '"]');
          let filteredAlt3Case = document.getElementById('work--list').querySelectorAll('.case--study[data-alt-3-category="' + targetFilter + '"]');
          let filteredAlt4Case = document.getElementById('work--list').querySelectorAll('.case--study[data-alt-4-category="' + targetFilter + '"]');
          filteredCase.forEach(filtered => {
            filtered.classList.add('is--filtered');
          })
          filteredAltCase.forEach(filteredAlt => {
            filteredAlt.classList.add('is--filtered');
          })
          filteredAlt2Case.forEach(filteredAlt2 => {
            filteredAlt2.classList.add('is--filtered');
          })
          filteredAlt3Case.forEach(filteredAlt3 => {
            filteredAlt3.classList.add('is--filtered');
          })
          filteredAlt4Case.forEach(filteredAlt4 => {
            filteredAlt4.classList.add('is--filtered');
          })
          // show filtered cat desc
          let filteredDesc = document.getElementById('work--list').querySelector('.cat--desc[data-cat-desc="' + targetFilter + '"]');
          filteredDesc.classList.add('is--visible');
        }

        return false;

      })
    })
  }

  // client logos
  const Logos =({ clientLogo }) => {
    return(
      <div className="col-md-3 mb-5 text-center logo">
        <img className="img-fluid" src={clientLogo.logo.url} alt={clientLogo.logo.alt} />
      </div>
    );
  };

  const loadClients = () => {
    let teamCont = document.getElementById('client--logos');
    teamCont.querySelectorAll('.logo').forEach(leader => {
      leader.classList.add('is--visible');
    });
    teamCont.querySelectorAll('.load--more').forEach(leaderBtn => {
      leaderBtn.classList.add('d-none');
    })
  }

  // Return the page if a document was retrieved from Prismic
  //
  if (work && menu && caseStudies?.results) {
    return (
      <Layout wrapperClass="work" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((work.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((work.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/work`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((work.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((work.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={work.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://www.launchsquad.com/work"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((work.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((work.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={work.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((work.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((work.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={work.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in bg--tan">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono mb-5">
                  <PrismicText field={work.data.title}></PrismicText>
                </h1>
                <div className="inline--images">
                  <PrismicRichText field={work.data.summary} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="more--clients py-5">
          <div className="container">
            <div id="client--logos" className="row pt-5">
              {work.data.logo_soup.map((clientLogo) => (
                <Logos clientLogo={clientLogo} key={clientLogo.url} />
              ))}
              <button className="btn btn-primary load--more mt-5" onClick={loadClients}>
                View All Clients
              </button>
            </div>
          </div>
        </section>

        <section className="case--filters py-5">
          <hr />
          <div className="container mt-5 pt-5">
            <div className="row-md-5">
              <div className="col-md-8">
                <h2 className="mb-5">
                  <PrismicText field={work.data.case_studies_header} />
                </h2>
                <h3 className="smono mb-3">
                  <PrismicText field={work.data.filter_header} />
                </h3>
                <ul className="nav nav--filter">
                  <li className="nav-item">
                    <button className="nav-item_filter active" data-filter="All" onClick={filterWork}>
                      All
                    </button>
                  </li>
                  {work.data.filters.map(item => {
                    return (
                      <li className="nav-item" key={`name-${((item.filter_name || {})[0] || {}).text}`}>
                        <button className="nav-item_filter" data-filter={`${((item.filter_name || {})[0] || {}).text}`} onClick={filterWork}>
                          <PrismicText field={item.filter_name} />
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="case--studies">
          <div id="work--list" className="container mt-5">
            {work.data.filters.map(item => {
              return (
                <div className="row cat--desc" data-cat-desc={`${((item.filter_name || {})[0] || {}).text}`} key={`desc-${((item.filter_name || {})[0] || {}).text}`}>
                  <div className="col-md-8 pb-5">
                    <h2><PrismicText field={item.filter_name} /></h2>
                    <PrismicRichText field={item.filter_description} />
                  </div>
                </div>
              )
            })}
            <CaseList casestudies={caseStudies?.results} />
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
