import { PrismicRichText, PrismicText } from "@prismicio/react";

// execution slice
//
export const Execution = ({ slice }) => (
  <section className="content-slice component--execution bg--blue">
    <div className="container">
      <div className="row">
        <div className="col">
          {slice.primary.subheader1 && (
          <h3 className="smono mb-5">
            <PrismicText field={slice.primary.subheader1} />
          </h3>
          )}
          {slice.primary.heading && (
          <h2
            className="has--bg-img"
            style={{
              backgroundImage: `url(${slice.primary.icon.url})`,
            }}
          >
            <PrismicText field={slice.primary.heading} />
          </h2>
          )}
        </div>
      </div>
      <div className="row">
        {slice.items.map(item => {
          return (
            <div 
              className="col-md-4"
              key={item.logo.id}
            >
              <div className="press--box mb-3 text-start">
                <img className="mb-3 img--press" src={item.logo.url} alt={item.logo.atl} />
                <PrismicRichText field={item.headline} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  </section>
);