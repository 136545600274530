import * as prismicH from "@prismicio/helpers";
import { PrismicRichText, PrismicText } from "@prismicio/react";

// case study item
//
export const SinglePerson = ({ person_details }) => {
  const name = prismicH.asText(person_details.data.name) || "Untitled";
  const role = prismicH.asText(person_details.data.role) || "Untitled";

  return (
    <article className="col-md-4 person mb-5">
      <figure
        className="cover--photo mb-4"
        style={{ backgroundImage: `url(${person_details.data.headshot.url})` }}
      />
      <h4 className="mt-3 mb-1">{name}</h4>
      <h5 className="mb-3">{role}</h5>
      <div className="bio">
        <PrismicRichText field={person_details.data.bio} />
      </div>
      {prismicH.asText(person_details.data.location)?.length > 1 && (
      <h5 className="smono">
        <PrismicText field={person_details.data.location} />
      </h5>
      )}
    </article>
  );
};
