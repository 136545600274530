import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSinglePrismicDocument, usePrismicDocumentsByType, usePrismicDocumentByUID, PrismicText, PrismicRichText, PrismicLink, SliceZone } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { StudioList } from "../components/StudioList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// case study component
//
export const StudioStudy = () => {
  const { uid } = useParams();
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [studioStudy, studioStudyState] = usePrismicDocumentByUID("studio_case_study", uid);
  const [studioStudies, studioStudiesState] = usePrismicDocumentsByType("studio_case_study", {
    orderings: [{ field: "my.studio_case_study.date", direction: "desc" }],
    pageSize: 100,
  });

  const notFound = studioStudyState.state === "failed" || menuState.state === "failed" || studioStudiesState === "failed";

  useEffect(() => {
    if (studioStudyState.state === "failed") {
      console.warn(
        "Case Study not found."
      );
    }
  }, [studioStudyState.state]);

  // return if case study exists
  if (studioStudy && menu && studioStudies?.results) {
    const currentCat = studioStudy.data.category;
    const currentCase = prismicH.asText(studioStudy.data.title) || "Untitled";

    // filter related case studies
    const relatedCases = () => {
      setTimeout(() => {
        let allCases = document.querySelector('#studio--study_list');
        allCases.querySelectorAll('.case--study').forEach(related => {
          related.classList.add('d-none');
        });
        let relatedCases = document.getElementById('studio--study_list').querySelectorAll('.case--study[data-category="' + currentCat + '"]');
        relatedCases.forEach(relatedCase => {
          relatedCase.classList.remove('d-none');
          relatedCase.classList.add('is--related');
        });
        // don't show current case study
        let current = document.getElementById('studio--study_list').querySelectorAll('.case--study[data-title="' + currentCase + '"]');
        console.log(current);
        current.forEach(currentCase => {
          currentCase.classList.remove('is--related');
          currentCase.classList.add('d-none');
        });
      }, 1000);
    }
    return(
      <Layout wrapperClass="studio--study_detail" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((studioStudy.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((studioStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/content-studio/${uid}`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((studioStudy.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((studioStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={studioStudy.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content={`https://www.launchsquad.com/content-studio/${uid}`}
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((studioStudy.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((studioStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={studioStudy.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((studioStudy.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((studioStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={studioStudy.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <aside className="back container">
          <div className="row pt-5">
            <div className="col">
              <Link to="/content-studio/"><img src="/images/back-arrow.svg" alt="back arrow" /> Back to All</Link>
            </div>
          </div>
        </aside>

        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono">
                  <PrismicText field={studioStudy.data.title}></PrismicText>
                </h1>
                <h5 className="mt-3">
                  <PrismicText field={studioStudy.data.summary}></PrismicText>
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col">
                <figure className="featured--image">
                  <figure className="client--logo">
                    <img src={studioStudy.data.client_logo.url} alt={studioStudy.data.client_logo.alt} />
                  </figure>
                  <img className="img-fluid" src={studioStudy.data.meta_image.url} alt={studioStudy.data.meta_image.alt} />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className="positioning bg--tan">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h3 className="smono mb-5">
                  <PrismicText field={studioStudy.data.subheader} />
                </h3>
              </div>
              <div className="col-md-8">
                <PrismicRichText field={studioStudy.data.lead_in} />
              </div>
            </div>
          </div>
        </section>

        <SliceZone slices={studioStudy.data.body} components={components} />

        <section className="related">
          <div className="container py-5">
            <div className="row">
              <div className="col">

                <h2 className="mb-5">Related Content</h2>

                <div id="work--list">
                  <StudioList studiostudies={studioStudies?.results} />
                </div>
                
                <aside className="back">
                  <Link to="/content-studio/"><img src="/images/back-arrow.svg" alt="back arrow" /> Back to All</Link>
                </aside>

                {relatedCases()}

              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};