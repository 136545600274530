import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSinglePrismicDocument, usePrismicDocumentsByType, usePrismicDocumentByUID, PrismicText, PrismicRichText, PrismicLink, SliceZone } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { PostList } from "../components/PostList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// blog post component
//
export const Post = () => {
  const { uid } = useParams();
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [blogPost, blogPostState] = usePrismicDocumentByUID("post", uid);
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {
    orderings: [{ field: "my.post.post_date", direction: "desc" }],
    pageSize: 100,
  });

  const notFound = blogPostState.state === "failed" || menuState.state === "failed" || blogPostsState === "failed";

  useEffect(() => {
    if (blogPostState.state === "failed") {
      console.warn(
        "Case Study not found."
      );
    }
  }, [blogPostState.state]);

  // return if case study exists
  if (blogPost && menu && blogPosts?.results) {
    // check for video post
    const hasVimeo = prismicH.asText(blogPost.data.vimeo_id) || "";
    const currentCat = blogPost.data.post_category;
    const currentPost = prismicH.asText(blogPost.data.post_title) || "Untitled";

    // filter related case studies
    const relatedPosts = () => {
      setTimeout(() => {
        let allPosts = document.querySelector('#post--list_all');
        allPosts.querySelectorAll('.blog--post').forEach(related => {
          related.classList.add('d-none');
        });
        let relatedPosts = document.getElementById('post--list_all').querySelectorAll('.blog--post[data-category="' + currentCat + '"]');
        relatedPosts.forEach(relatedPost => {
          relatedPost.classList.remove('d-none');
          relatedPost.classList.add('is--related');
        });
        // don't show current case study
        let current = document.getElementById('post--list_all').querySelectorAll('.blog--post[data-title="' + currentPost + '"]');
        current.forEach(currentPost => {
          currentPost.classList.remove('is--related');
          currentPost.classList.add('d-none');
        });
      }, 1000);
    }

    return(
      <Layout wrapperClass="blog--post_detail" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((blogPost.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((blogPost.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/blog-library/${uid}`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((blogPost.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((blogPost.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={blogPost.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content={`https://www.launchsquad.com/blog-library/${uid}`}
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="article"
            data-react-helmet="true"
          />
          <meta
            property="og:author"
            content={((blogPost.data.post_author || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((blogPost.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((blogPost.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={blogPost.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((blogPost.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((blogPost.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={blogPost.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <aside className="back container">
          <div className="row py-5">
            <div className="col">
              <Link to="/blog" className="smono"><img src="/images/back-arrow.svg" alt="back arrow" /> Back to All</Link>
            </div>
          </div>
        </aside>

        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
              <span className="post--cat">{blogPost.data.post_category}</span>
                <h1 className="my-4">
                  <PrismicText field={blogPost.data.post_title} />
                </h1>
              <p className="post--author smono">
                by <PrismicText field={blogPost.data.post_author} />
              </p>
              </div>
            </div>
          </div>
        </section>

        {hasVimeo?.length > 1 && (
        <section className="hero has--video">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="embed-container">
                  <iframe src={`https://player.vimeo.com/video/${((blogPost.data.vimeo_id || {})[0] || {}).text}`} frameBorder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        )}

        {hasVimeo?.length < 1 && (
        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <figure className="featured--image">
                  <img className="img-fluid" src={blogPost.data.meta_image.url} alt={blogPost.data.meta_image.alt} />
                </figure>
              </div>
            </div>
          </div>
        </section>
        )}        

        <section className="blog--content">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <PrismicRichText field={blogPost.data.post_content} />
              </div>
            </div>
          </div>
        </section>

        <SliceZone slices={blogPost.data.body} components={components} />

        <section className="related py-5 bg--tan">
          <div className="container">
            <div className="row">
              <div className="col">

                <h2 className="mb-5">Related Articles</h2>

                <section className="blog--posts">
                  <div className="container">
                    <div id="blog--list">
                      <PostList posts={blogPosts?.results} />
                    </div>
                  </div>
                </section>

                <aside className="back">
                  <Link to="/blog" className="smono"><img src="/images/back-arrow.svg" alt="back arrow" /> Back to All</Link>
                </aside>

                {relatedPosts()}

              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};