import { PrismicLink } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

// case study item
//
export const CaseItem = ({ case_study }) => {
  const title = prismicH.asText(case_study.data.title) || "Untitled";
  const lead = prismicH.asText(case_study.data.summary) || "Untitled";
  const altCat = case_study.data.alt_category;
  const altCat2 = case_study.data.alt_category_2;
  const altCat3 = case_study.data.alt_category_3;
  const altCat4 = case_study.data.alt_category_4;

  return (
    <article className={`col-md-4 mb-5 case--study`} data-category={case_study.data.category} data-alt-category={case_study.data.alt_category} data-alt-2-category={altCat2} data-alt-3-category={altCat3} data-alt-4-category={altCat4} data-title={title}>
      <PrismicLink document={case_study}>
        <figure
          className="cover--photo cover--photo_case-study mb-4"
          style={{ backgroundImage: `url(${case_study.data.meta_image.url})` }}
        />
        <h4 className="mt-3 mb-3">{title}</h4>
        <p className="case--lead">{lead}</p>
        <span className="post--cat mt-2 me-2">{case_study.data.category}</span>
        {altCat?.length > 1 && (
        <span className="post--cat mt-2 me-2">{altCat}</span>
        )}
        {altCat2?.length > 1 && (
        <span className="post--cat mt-2 me-2">{altCat2}</span>
        )}
        {altCat3?.length > 1 && (
        <span className="post--cat mt-2 me-2">{altCat3}</span>
        )}
        {altCat4?.length > 1 && (
        <span className="post--cat mt-2 me-2">{altCat4}</span>
        )}
      </PrismicLink>
    </article>
  );
};
