import { PrismicRichText } from "@prismicio/react";

// text block slice
//
export const TextBlock = ({ slice }) => (
  <section className="content-slice component--text">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <PrismicRichText field={slice.primary.copy} />
        </div>
      </div>
    </div>
  </section>
);