import { useEffect } from "react";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { PostList } from "../components/PostList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// blog landing page
//
export const Blog = () => {
  const [blog, blogState] = useSinglePrismicDocument("blog_landing");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {
    orderings: [{ field: "my.post.post_date", direction: "desc" }],
    pageSize: 100,
  });

  const notFound = blogState.state === "failed" || menuState.state === "failed" || blogPostsState.state == "failed";

  useEffect(() => {
    if (blogState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [blogState.state]);

  // filter posts
  function filterPosts() {
    document.querySelectorAll('.nav-item_filter').forEach(filter => {
      filter.addEventListener('click', e => {

        // get target filter
        let target = e.target;
        let targetFilter = target.getAttribute('data-filter');

        // filter nav updates
        document.querySelectorAll('.nav-item_filter').forEach(activeFilter => {
          activeFilter.classList.remove('active');
        })
        // active filter class
        target.classList.add('active');

        if ( targetFilter == 'All' ) {
          // show all
          let allPosts = document.getElementById('blog--list').querySelectorAll('.blog--post');
          allPosts.forEach(caseDetail => {
            caseDetail.classList.remove('filtering');
            caseDetail.classList.remove('is--filtered');
          })
        } else {
          // hide all to start
          let hidePosts = document.getElementById('blog--list').querySelectorAll('.blog--post');
          hidePosts.forEach(caseLoad => {
            caseLoad.classList.add('filtering');
            caseLoad.classList.remove('is--filtered');
          })
          // show filtered
          let filteredPosts = document.getElementById('blog--list').querySelectorAll('.blog--post[data-category="' + targetFilter + '"]');
          filteredPosts.forEach(filtered => {
            filtered.classList.add('is--filtered');
          })
        }

        return false;

      })
    })
  }

  // Return the page if a document was retrieved from Prismic
  //
  if (blog && menu && blogPosts?.results) {
    return (
      <Layout wrapperClass="blog" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((blog.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((blog.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/blog`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((blog.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((blog.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={blog.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://www.launchsquad.com/blog"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((blog.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((blog.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={blog.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((blog.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((blog.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={blog.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in bg--peach">
          <div className="container">
            <div className="row">
              <div className="col-md-4 order-md-2 text-end">
                <img id="squad-logo" className="img-fluid" src={blog.data.squad_logo.url} alt={blog.data.squad_logo.alt} />
              </div>
              <div className="col-md-8">
                <h1 className="smono mb-5">
                  <PrismicText field={blog.data.title}></PrismicText>
                </h1>
                <div className="inline--images">
                  <PrismicRichText field={blog.data.summary} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="case--filters py-5">
          <div className="container">
            <div className="row pt-5">
              <div className="col-md-8">
                <h3 className="smono mb-3">
                  <PrismicText field={blog.data.filter_header} />
                </h3>
                <ul className="nav nav--filter">
                  <li className="nav-item">
                    <button id="show-all" className="nav-item_filter active" data-filter="All" onClick={filterPosts}>
                      All
                    </button>
                  </li>
                  {blog.data.filters.map(item => {
                    return (
                      <li className="nav-item" key={`name-${((item.filter_name || {})[0] || {}).text}`}>
                        <button className="nav-item_filter" data-filter={`${((item.filter_name || {})[0] || {}).text}`} onClick={filterPosts}>
                          <PrismicText field={item.filter_name} />
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="blog--posts pt-4 pb-5">
          <div className="container">
            <div id="blog--list">
              <PostList posts={blogPosts?.results} />
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
