import { PrismicRichText, PrismicText } from "@prismicio/react";

// blockquote slice
//
export const BlockQuote = ({ slice }) => (
  <section className="content-slice component--quote">
    <div className="container">
      <div className="row">
        <div className="col-md-5 offset-md-3">
          <figure>
            <blockquote cite={slice.primary.author_citation}>
              <p>
                <PrismicText field={slice.primary.quote} />
              </p>
            </blockquote>
            <figcaption>
              <cite>
                <PrismicRichText field={slice.primary.author_citation} />
              </cite>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </section>
);