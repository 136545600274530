import { PrismicLink } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

// studio study item
//
export const StudioItem = ({ studio_study }) => {
  const title = prismicH.asText(studio_study.data.title) || "Untitled";
  const lead = prismicH.asText(studio_study.data.summary) || "Untitled";
  const altCat = studio_study.data.alt_category;

  return (
    <article className="col-md-4 mb-5 case--study" data-category={studio_study.data.category} data-alt-category={altCat} data-title={title}>
      <PrismicLink document={studio_study}>
        <figure
          className="cover--photo cover--photo_case-study mb-4"
          style={{ backgroundImage: `url(${studio_study.data.meta_image.url})` }}
        />
        <h4 className="mt-3 mb-3">{title}</h4>
        <p className="case--lead">{lead}</p>
        <span className="post--cat mt-2">{studio_study.data.category}</span>
        {altCat?.length > 3 && (
        <span className="post--cat mt-2 ms-2">{altCat}</span>
        )}
      </PrismicLink>
    </article>
  );
};
