import { useEffect } from "react";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { StudioList } from "../components/StudioList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// content studio landing page
//
export const ContentStudio = () => {
  const [studio, studioState] = useSinglePrismicDocument("content_studio");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [studioStudies, studioStudiesState] = usePrismicDocumentsByType("studio_case_study", {
    orderings: [{ field: "my.studio_case_study.date", direction: "desc" }],
  });

  const notFound = studioStudiesState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (studioStudiesState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [studioStudiesState.state]);

  // filter studio studies
  function filterStudies() {
    document.querySelectorAll('.nav-item_filter').forEach(filter => {
      filter.addEventListener('click', e => {

        // get target filter
        let target = e.target;
        let targetFilter = target.getAttribute('data-filter');

        // filter nav updates
        document.querySelectorAll('.nav-item_filter').forEach(activeFilter => {
          activeFilter.classList.remove('active');
        })
        // active filter class
        target.classList.add('active');

        if ( targetFilter == 'All' ) {
          // show all
          let filterCats = document.querySelectorAll('.cat--desc');
          filterCats.forEach(cat => {
            cat.classList.remove('is--visible');
          })
          let allCases = document.getElementById('work--list').querySelectorAll('.case--study');
          allCases.forEach(caseDetail => {
            caseDetail.classList.remove('filtering');
            caseDetail.classList.remove('is--filtered');
          })
        } else {
          // hide all to start
          let hideCats = document.querySelectorAll('.cat--desc');
          hideCats.forEach(cat => {
            cat.classList.remove('is--visible');
          })
          let hideCases = document.getElementById('work--list').querySelectorAll('.case--study');
          hideCases.forEach(caseLoad => {
            caseLoad.classList.add('filtering');
            caseLoad.classList.remove('is--filtered');
          })
          // show filtered
          let filteredCase = document.getElementById('work--list').querySelectorAll('.case--study[data-category="' + targetFilter + '"]');
          let filteredAltCase = document.getElementById('work--list').querySelectorAll('.case--study[data-alt-category="' + targetFilter + '"]');
          filteredCase.forEach(filtered => {
            filtered.classList.add('is--filtered');
          })
          filteredAltCase.forEach(filteredAlt => {
            filteredAlt.classList.add('is--filtered');
          })
        }

        return false;

      })
    })
  }

  // Return the page if a document was retrieved from Prismic
  //
  if (studio && menu && studioStudies?.results) {
    return (
      <Layout wrapperClass="studio" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((studio.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((studio.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/content-studio`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((studio.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((studio.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={studio.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://www.launchsquad.com/content-studio"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((studio.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((studio.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={studio.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((studio.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((studio.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={studio.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in bg--green">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono mb-5">
                  <PrismicText field={studio.data.title}></PrismicText>
                </h1>
                <div className="inline--images">
                  <PrismicRichText field={studio.data.summary} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="case--filters py-5">
          <div className="container pt-5">
            <div className="row-md-5">
              <div className="col-md-8">
                <h3 className="smono mb-3">
                  <PrismicText field={studio.data.filter_header} />
                </h3>
                <ul className="nav nav--filter">
                  <li className="nav-item">
                    <button id="show-all" className="nav-item_filter active" data-filter="All" onClick={filterStudies}>
                      All
                    </button>
                  </li>
                  {studio.data.filter_categories.map(item => {
                    return (
                      <li className="nav-item" key={`name-${((item.filter_name || {})[0] || {}).text}`}>
                        <button className="nav-item_filter" data-filter={`${((item.filter_name || {})[0] || {}).text}`} onClick={filterStudies}>
                          <PrismicText field={item.filter_name} />
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="case--studies pt-5">
          <hr />
          <div className="container pt-5 mt-5">
            <div id="work--list">
              <StudioList studiostudies={studioStudies?.results} />
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
