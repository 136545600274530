import { useEffect } from "react";
import { useSinglePrismicDocument, PrismicText, PrismicRichText } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// contact component
//
export const Contact = () => {
  const [contact, contactState] = useSinglePrismicDocument("contact");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound = contactState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (contactState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [contactState.state]);

  // Return the page if a document was retrieved from Prismic
  if (contact && menu) {
    return (
      <Layout wrapperClass="contact" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((contact.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((contact.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/contact`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((contact.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((contact.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={contact.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://www.launchsquad.com/contact"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((contact.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((contact.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={contact.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((contact.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((contact.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={contact.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono mb-5">
                  <PrismicText field={contact.data.title}></PrismicText>
                </h1>
                <h2 className="mt-5">
                  <PrismicText field={contact.data.summary}></PrismicText>
                </h2>
                <PrismicRichText field={contact.data.lead_in} />             
              </div>
            </div>
            <div className="row my-5">
            {contact.data.locations.map(loc => {
              return (
              <div className="col location">
                <PrismicRichText field={loc.location_details} />
              </div>
              )
            })}
            </div>
            <div className="row">
              <div className="col">
                <iFrame src="https://forms.monday.com/forms/embed/22a797994c55f965d3d094e2f76942b1?r=use1" />
              </div>
            </div>
          </div>
        </section>

        <section className="careers--blurb bg--tan mt-5">
          <div className="container">
            <div className="row align-items-center py-5">
              <div className="col-md-5">
                <img className="img-fluid" src={contact.data.careers_image.url} alt={contact.data.careers_image.alt} />
              </div>
              <div className="col-md-6 offset-md-1">
                <h3 className="smono mb-5">
                  <PrismicText field={contact.data.careers_header} />
                </h3>
                <PrismicRichText field={contact.data.careers_copy} />
              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
