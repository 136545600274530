import { PrismicProvider, PrismicToolbar } from "@prismicio/react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

import { client, repositoryName } from "./prismic";
import { HomePage } from "./pages/HomePage";
import { About } from "./pages/About";
import { Approach } from "./pages/Approach";
import { Careers } from "./pages/Careers";
import { Contact } from "./pages/Contact";
import { NotFound } from "./pages/NotFound";
import { Page } from "./pages/Page";
import { Work } from "./pages/Work";
import { CaseStudy } from "./pages/CaseStudy";
import { Blog } from "./pages/Blog";
import { Post } from "./pages/Post";
import { ContentStudio } from "./pages/ContentStudio";
import { StudioStudy } from "./pages/StudioStudy";
import { Preview } from "./pages/Preview";

import "./scss/app.scss";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-1X70107FZD'
}

TagManager.initialize(tagManagerArgs)

export const App = () => {
  return (
    <PrismicProvider
      client={client}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/approach" element={<Approach />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/preview" element={<Preview />} />
          <Route path="/:uid" element={<Page />} />
          <Route path="/work" element={<Work />} />
          <Route path="case/:uid" element={<CaseStudy />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-library/:uid" element={<Post />} />
          <Route path="/content-studio" element={<ContentStudio />} />
          <Route path="/content-studio/:uid" element={<StudioStudy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
  );
};
