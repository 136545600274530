import * as prismicH from "@prismicio/helpers";
// video slice
//

export const Video = ({ slice }) => (
  <section className="content-slice component--video">
    <div className="container">
      <div className="row">
        <div className="col">
        <div className="embed-container">
          {prismicH.asText(slice.primary.vimeo_id)?.length > 1 && (
          <iFrame src={`https://player.vimeo.com/video/${((slice.primary.vimeo_id || {})[0] || {}).text}`} frameBorder="0"></iFrame>
          )}
          {prismicH.asText(slice.primary.youtube_id)?.length > 1 && (
          <iFrame src={`https://www.youtube.com/embed/${((slice.primary.youtube_id || {})[0] || {}).text}`} frameBorder="0" allowfullscreen></iFrame>
          )}
        </div>
        </div>
      </div>
    </div>
  </section>
);