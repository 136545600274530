import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  SliceZone,
  usePrismicDocumentByUID,
  useSinglePrismicDocument,
  PrismicText,
} from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

/**
 * Website page component
 */
export const Page = () => {
  const { uid } = useParams();

  const [page, pageState] = usePrismicDocumentByUID("page", uid);
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound = pageState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (pageState.state === "failed") {
      console.warn(
        "Page document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, []);

  // Return the page if a document was retrieved from Prismic
  if (page && menu) {
    return (
      <Layout wrapperClass="page" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((page.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((page.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/${uid}`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((page.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((page.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={page.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content={`https://www.launchsquad.com/${uid}`}
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((page.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((page.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={page.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((page.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((page.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={page.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono mb-5">
                  <PrismicText field={page.data.title}></PrismicText>
                </h1>
                <h2 className="has--img mt-5">
                  <img src={page.data.icon.url} alt={page.data.icon.alt} />
                  <PrismicText field={page.data.summary}></PrismicText>
                </h2>
              </div>
            </div>
          </div>
        </section>

        <SliceZone slices={page.data.page_content} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
