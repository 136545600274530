import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSinglePrismicDocument, usePrismicDocumentsByType, usePrismicDocumentByUID, PrismicText, PrismicRichText, SliceZone } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { CaseList } from "../components/CaseList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// case study component
//
export const CaseStudy = () => {
  const { uid } = useParams();
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [caseStudy, caseStudyState] = usePrismicDocumentByUID("case_study", uid);
  const [caseStudies, caseStudiesState] = usePrismicDocumentsByType("case_study", {
    orderings: [{ field: "my.case_study.date", direction: "desc" }],
    pageSize: 100,
  });

  const notFound = caseStudyState.state === "failed" || menuState.state === "failed" || caseStudiesState === "failed";

  useEffect(() => {
    if (caseStudyState.state === "failed") {
      console.warn(
        "Case Study not found."
      );
    }
  }, [caseStudyState.state]);

  // return if case study exists
  if (caseStudy && menu && caseStudies?.results ) {
    const currentCat = caseStudy.data.category;
    const currentCase = prismicH.asText(caseStudy.data.title) || "Untitled";
    const altCat = caseStudy.data.alt_category;
    const altCat2 = caseStudy.data.alt_category_2;
    const altCat3 = caseStudy.data.alt_category_3;
    const altCat4 = caseStudy.data.alt_category_4;

    // filter related case studies
    const relatedCases = () => {
      setTimeout(() => {
        let allCases = document.querySelector('#case--study_list');
        allCases.querySelectorAll('.case--study').forEach(related => {
          related.classList.add('d-none');
        });

        // get related from currentCat
        let relatedCases = document.getElementById('case--study_list').querySelectorAll('.case--study[data-category="' + currentCat + '"]');
        relatedCases.forEach(relatedCase => {
          relatedCase.classList.remove('d-none');
          relatedCase.classList.add('is--related');
        });
        let relatedAltCases = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-category="' + currentCat + '"]');
        relatedAltCases.forEach(relatedAltCase => {
          relatedAltCase.classList.remove('d-none');
          relatedAltCase.classList.add('is--related');
        });
        let relatedAlt2Cases = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-2-category="' + currentCat + '"]');
        relatedAlt2Cases.forEach(relatedAlt2Case => {
          relatedAlt2Case.classList.remove('d-none');
          relatedAlt2Case.classList.add('is--related');
        });
        let relatedAlt3Cases = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-3-category="' + currentCat + '"]');
        relatedAlt3Cases.forEach(relatedAlt3Case => {
          relatedAlt3Case.classList.remove('d-none');
          relatedAlt3Case.classList.add('is--related');
        });
        let relatedAlt4Cases = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-4-category="' + currentCat + '"]');
        relatedAlt4Cases.forEach(relatedAlt4Case => {
          relatedAlt4Case.classList.remove('d-none');
          relatedAlt4Case.classList.add('is--related');
        });

        // get related from altCat
        let relatedCases2 = document.getElementById('case--study_list').querySelectorAll('.case--study[data-category="' + altCat + '"]');
        relatedCases2.forEach(relatedCase => {
          relatedCase.classList.remove('d-none');
          relatedCase.classList.add('is--related');
        });
        let relatedAltCases2 = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-category="' + altCat + '"]');
        relatedAltCases2.forEach(relatedAltCase => {
          relatedAltCase.classList.remove('d-none');
          relatedAltCase.classList.add('is--related');
        });
        let relatedAlt2Cases2 = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-2-category="' + altCat + '"]');
        relatedAlt2Cases2.forEach(relatedAlt2Case => {
          relatedAlt2Case.classList.remove('d-none');
          relatedAlt2Case.classList.add('is--related');
        });
        let relatedAlt3Cases2 = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-3-category="' + altCat + '"]');
        relatedAlt3Cases2.forEach(relatedAlt3Case => {
          relatedAlt3Case.classList.remove('d-none');
          relatedAlt3Case.classList.add('is--related');
        });
        let relatedAlt4Cases2 = document.getElementById('case--study_list').querySelectorAll('.case--study[data-alt-4-category="' + altCat + '"]');
        relatedAlt4Cases2.forEach(relatedAlt4Case => {
          relatedAlt4Case.classList.remove('d-none');
          relatedAlt4Case.classList.add('is--related');
        });

        // don't show current case study
        let current = document.getElementById('case--study_list').querySelectorAll('.case--study[data-title="' + currentCase + '"]');
        current.forEach(currentCase => {
          currentCase.classList.remove('is--related');
          currentCase.classList.add('d-none');
        });
      }, 500);

      setTimeout(() => {
        // only show 3 related
        let firstRel = document.querySelector('#case--study_list').getElementsByClassName('is--related')[0];
        firstRel.classList.add('is--visible');
        let secondRel = document.querySelector('#case--study_list').getElementsByClassName('is--related')[1];
        secondRel.classList.add('is--visible');
        let thirdRel = document.querySelector('#case--study_list').getElementsByClassName('is--related')[2];
        thirdRel.classList.add('is--visible');
      }, 750);
    }

    return(
      <Layout wrapperClass="case--study_detail" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((caseStudy.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((caseStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/case/${uid}`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((caseStudy.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((caseStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={caseStudy.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content={`https://www.launchsquad.com/case/${uid}`}
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((caseStudy.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((caseStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={caseStudy.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((caseStudy.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((caseStudy.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={caseStudy.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <aside className="back container">
          <div className="row pt-5">
            <div className="col">
              <Link to="/work"><img src="/images/back-arrow.svg" alt="back arrow" /> Back to All</Link>
            </div>
          </div>
        </aside>

        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono">
                  <PrismicText field={caseStudy.data.title}></PrismicText>
                </h1>
                <h5 className="mt-3">
                  <PrismicText field={caseStudy.data.summary}></PrismicText>
                </h5>
                <span className="post--cat mt-2 me-2 cat">{caseStudy.data.category}</span>
                {altCat?.length > 1 && (
                <span className="post--cat mt-2 me-2 cat-alt">{altCat}</span>
                )}
                {altCat2?.length > 1 && (
                <span className="post--cat mt-2 me-2 cat-2">{altCat2}</span>
                )}
                {altCat3?.length > 1 && (
                <span className="post--cat mt-2 me-2 cat-3">{altCat3}</span>
                )}
                {altCat4?.length > 1 && (
                <span className="post--cat mt-2 me-2 cat-4">{altCat4}</span>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="hero">
          <div className="container">
            <div className="row">
              <div className="col">
                <figure className="featured--image">
                  <figure className="client--logo">
                    <img src={caseStudy.data.client_logo.url} alt={caseStudy.data.client_logo.alt} />
                  </figure>
                  <img className="img-fluid" src={caseStudy.data.meta_image.url} alt={caseStudy.data.meta_image.alt} />
                </figure>
              </div>
            </div>
          </div>
        </section>

        <section className="positioning bg--tan">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h3 className="smono mb-5">
                  <PrismicText field={caseStudy.data.subheader} />
                </h3>
              </div>
              <div className="col-md-8">
                <PrismicRichText field={caseStudy.data.lead_in} />
              </div>
            </div>
          </div>
        </section>

        <SliceZone slices={caseStudy.data.body} components={components} />

        <section className="related mt-5">
          <div className="container py-5">
            <div className="row">
              <div className="col">
                
                <h2 className="mb-5">Related Case Studies</h2>

                <CaseList casestudies={caseStudies?.results} />

                <aside className="back mt-5">
                  <Link to="/work"><img src="/images/back-arrow.svg" alt="back arrow" /> Back to All</Link>
                </aside>

                {relatedCases()}

              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};