import { PrismicLink, PrismicText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

// post item
//
export const PostItem = ({ post }) => {
  const title = prismicH.asText(post.data.post_title) || "Untitled";
  const lead = prismicH.asText(post.data.lead_in) || "Untitled";
  const auth = prismicH.asText(post.data.post_author) || "Unknown";
  //const hasVimeo = prismicH.asText(post.data.vimeo_id) || "";
  const hasMetaImg = post.data.meta_image.url;

  return (
    <article className="col-md-4 mb-5 blog--post" data-category={post.data.post_category} data-title={title}>
      <PrismicLink document={post}>
        {/* {hasVimeo?.length > 1 && (
        <figure
          className="cover--photo mb-4"
          style={{ backgroundImage: `url(https://vumbnail.com/${((post.data.vimeo_id || {})[0] || {}).text}_large.jpg)` }}
        />
        )}
        {hasVimeo?.length < 1 && (
        <figure
          className="cover--photo mb-4"
          style={{ backgroundImage: `url(${post.data.meta_image.url})` }}
        />
        )} */}
        { hasMetaImg ? (
          <figure
            className="cover--photo mb-4"
            style={{ backgroundImage: `url(${post.data.meta_image.url})` }}
          />
        ) :
          <figure
            className="cover--photo mb-4"
            style={{ backgroundImage: `url(https://vumbnail.com/${((post.data.vimeo_id || {})[0] || {}).text}_large.jpg)` }}
          />
        }
        <span className="post--cat">{post.data.post_category}</span>
        <h5 className="mt-3 mb-3">{title}</h5>
        <p className="post--lead">{lead}</p>
        <p className="post--author">{auth}</p>
      </PrismicLink>
    </article>
  );
};
