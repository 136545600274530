// wide image slice
//
export const WideImage = ({ slice }) => (

  <section className="content-slice component--image">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <img className="img-fluid" src={slice.primary.image.url} alt={slice.primary.image.alt} />
        </div>
      </div>
    </div>
  </section>
);