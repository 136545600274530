import { PrismicText, PrismicLink, PrismicRichText } from "@prismicio/react";

/**
 * Site header/nav component
 */
export const Header = ({ menuDoc }) => {
  if (menuDoc) {

    // Main nav comp
    const MenuLink = ({ menuLink }) => {
      return (
        <li className={`nav-link ${((menuLink.label || {})[0] || {}).text}`}>
          <PrismicLink field={menuLink.link} aria-label={((menuLink.label || {})[0] || {}).text}>
            <PrismicText field={menuLink.label} />
          </PrismicLink>
        </li>
      );
    };

    // Secondary nav comp
    const SecondaryLink = ({ secLink }) => {
      return (
        <li className={`nav-link ${((secLink.label || {})[0] || {}).text}`}>
          <PrismicLink field={secLink.link} aria-label={((secLink.label || {})[0] || {}).text}>
            <PrismicText field={secLink.label} />
          </PrismicLink>
        </li>
      );
    };

    // open the nav
    const openNav = () => {
      window.scrollTo(0,0);
      //document.body.classList.add('modal--open');
      document.getElementById('mobileNav').classList.add('is--open');
    };

    // close the nav
    const closeNav = () => {
      window.scrollTo(0,0);
      //document.body.classList.remove('modal--open');
      document.getElementById('mobileNav').classList.remove('is--open');
    };

    // check if banner
    const bannerText = menuDoc.data.banner;

    return (
      <header id="header">
        {bannerText?.length > 0 && (
        <aside id="banner" className="smono lg" role="banner">
          <PrismicRichText field={menuDoc.data.banner} />
        </aside>
        )}
        <div className="container-fluid px-5 header--main">
          <div className="row py-3 align-items-center">
            <div className="col-md-1">
              <PrismicLink href="/" className="brand" aria-label="Homepage">
                <svg width="59" height="35" viewBox="0 0 59 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M29.5 35C45.7941 35 59 17.5 59 17.5C59 17.5 45.7941 0 29.5 0C13.2059 0 0 17.5 0 17.5C0 17.5 13.2059 35 29.5 35ZM29.5972 30.7223C37.125 30.7223 43.2275 24.6284 43.2275 17.1112C43.2275 9.59396 37.125 3.50005 29.5972 3.50005C22.0693 3.50005 15.9668 9.59396 15.9668 17.1112C15.9668 24.6284 22.0693 30.7223 29.5972 30.7223Z" />
                  <path d="M38.3471 17.4546C38.3471 17.0522 36.3451 16.7049 33.4482 16.544C32.9264 16.5143 32.6677 15.8959 33.0155 15.5062C34.9497 13.3461 36.1203 11.6858 35.8361 11.402C35.552 11.1182 33.8893 12.2872 31.7261 14.2186C31.3359 14.5659 30.7166 14.3076 30.6869 13.7866C30.5257 10.8937 30.1779 8.89453 29.7792 8.89453C29.3763 8.89453 29.0285 10.8937 28.8673 13.7866C28.8376 14.3076 28.2183 14.5659 27.8281 14.2186C25.6649 12.2872 24.0022 11.1182 23.718 11.402C23.4338 11.6858 24.6045 13.3461 26.5387 15.5062C26.8865 15.8959 26.6277 16.5143 26.106 16.544C23.209 16.7049 21.207 17.0522 21.207 17.4546C21.207 17.857 23.209 18.2043 26.106 18.3652C26.6277 18.3949 26.8865 19.0133 26.5387 19.403C24.6045 21.5631 23.4338 23.2234 23.718 23.5072C24.0022 23.791 25.6649 22.622 27.8281 20.6906C28.2183 20.3433 28.8376 20.6016 28.8673 21.1226C29.0285 24.0155 29.3763 26.0147 29.7792 26.0147C30.1822 26.0147 30.53 24.0155 30.6911 21.1226C30.7208 20.6016 31.3401 20.3433 31.7303 20.6906C33.8935 22.622 35.5562 23.791 35.8404 23.5072C36.1246 23.2234 34.9539 21.5631 33.0198 19.403C32.6719 19.0133 32.9307 18.3949 33.4524 18.3652C36.3451 18.2001 38.3471 17.8527 38.3471 17.4546Z" />
                </svg>
              </PrismicLink>
            </div>
            <div className="col-md-5">
              <nav>
                <ul className="nav nav--primary">
                  {menuDoc.data.menu_links.map((menuLink) => (
                    <MenuLink menuLink={menuLink} key={menuLink.link.id} />
                  ))}
                </ul>
              </nav>
            </div>
            <div className="col-md-6">
              <nav>
                <ul className="nav nav--primary_alt justify-content-end">
                  {menuDoc.data.secondary_links.map((secLink) => (
                    <SecondaryLink secLink={secLink} key={secLink.link.id} />
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="container-fluid header--mobile">
          <div className="row py-3 align-items-center">
            <div className="col-6">
              <PrismicLink href="/" className="brand" aria-label="Homepage">
                <svg width="59" height="35" viewBox="0 0 59 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M29.5 35C45.7941 35 59 17.5 59 17.5C59 17.5 45.7941 0 29.5 0C13.2059 0 0 17.5 0 17.5C0 17.5 13.2059 35 29.5 35ZM29.5972 30.7223C37.125 30.7223 43.2275 24.6284 43.2275 17.1112C43.2275 9.59396 37.125 3.50005 29.5972 3.50005C22.0693 3.50005 15.9668 9.59396 15.9668 17.1112C15.9668 24.6284 22.0693 30.7223 29.5972 30.7223Z" />
                  <path d="M38.3471 17.4546C38.3471 17.0522 36.3451 16.7049 33.4482 16.544C32.9264 16.5143 32.6677 15.8959 33.0155 15.5062C34.9497 13.3461 36.1203 11.6858 35.8361 11.402C35.552 11.1182 33.8893 12.2872 31.7261 14.2186C31.3359 14.5659 30.7166 14.3076 30.6869 13.7866C30.5257 10.8937 30.1779 8.89453 29.7792 8.89453C29.3763 8.89453 29.0285 10.8937 28.8673 13.7866C28.8376 14.3076 28.2183 14.5659 27.8281 14.2186C25.6649 12.2872 24.0022 11.1182 23.718 11.402C23.4338 11.6858 24.6045 13.3461 26.5387 15.5062C26.8865 15.8959 26.6277 16.5143 26.106 16.544C23.209 16.7049 21.207 17.0522 21.207 17.4546C21.207 17.857 23.209 18.2043 26.106 18.3652C26.6277 18.3949 26.8865 19.0133 26.5387 19.403C24.6045 21.5631 23.4338 23.2234 23.718 23.5072C24.0022 23.791 25.6649 22.622 27.8281 20.6906C28.2183 20.3433 28.8376 20.6016 28.8673 21.1226C29.0285 24.0155 29.3763 26.0147 29.7792 26.0147C30.1822 26.0147 30.53 24.0155 30.6911 21.1226C30.7208 20.6016 31.3401 20.3433 31.7303 20.6906C33.8935 22.622 35.5562 23.791 35.8404 23.5072C36.1246 23.2234 34.9539 21.5631 33.0198 19.403C32.6719 19.0133 32.9307 18.3949 33.4524 18.3652C36.3451 18.2001 38.3471 17.8527 38.3471 17.4546Z" />
                </svg>
              </PrismicLink>
            </div>
            <div className="col-6 text-end">
              <button id="mobile--nav_trigger" onClick={openNav}>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <hr />
        <aside id="mobileNav">
          <div className="container-fluid">
            <div className="row py-3 align-items-center">
              <div className="col-6">
                <PrismicLink href="/" className="brand" aria-label="Homepage">
                  <svg width="59" height="35" viewBox="0 0 59 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M29.5 35C45.7941 35 59 17.5 59 17.5C59 17.5 45.7941 0 29.5 0C13.2059 0 0 17.5 0 17.5C0 17.5 13.2059 35 29.5 35ZM29.5972 30.7223C37.125 30.7223 43.2275 24.6284 43.2275 17.1112C43.2275 9.59396 37.125 3.50005 29.5972 3.50005C22.0693 3.50005 15.9668 9.59396 15.9668 17.1112C15.9668 24.6284 22.0693 30.7223 29.5972 30.7223Z" />
                    <path d="M38.3471 17.4546C38.3471 17.0522 36.3451 16.7049 33.4482 16.544C32.9264 16.5143 32.6677 15.8959 33.0155 15.5062C34.9497 13.3461 36.1203 11.6858 35.8361 11.402C35.552 11.1182 33.8893 12.2872 31.7261 14.2186C31.3359 14.5659 30.7166 14.3076 30.6869 13.7866C30.5257 10.8937 30.1779 8.89453 29.7792 8.89453C29.3763 8.89453 29.0285 10.8937 28.8673 13.7866C28.8376 14.3076 28.2183 14.5659 27.8281 14.2186C25.6649 12.2872 24.0022 11.1182 23.718 11.402C23.4338 11.6858 24.6045 13.3461 26.5387 15.5062C26.8865 15.8959 26.6277 16.5143 26.106 16.544C23.209 16.7049 21.207 17.0522 21.207 17.4546C21.207 17.857 23.209 18.2043 26.106 18.3652C26.6277 18.3949 26.8865 19.0133 26.5387 19.403C24.6045 21.5631 23.4338 23.2234 23.718 23.5072C24.0022 23.791 25.6649 22.622 27.8281 20.6906C28.2183 20.3433 28.8376 20.6016 28.8673 21.1226C29.0285 24.0155 29.3763 26.0147 29.7792 26.0147C30.1822 26.0147 30.53 24.0155 30.6911 21.1226C30.7208 20.6016 31.3401 20.3433 31.7303 20.6906C33.8935 22.622 35.5562 23.791 35.8404 23.5072C36.1246 23.2234 34.9539 21.5631 33.0198 19.403C32.6719 19.0133 32.9307 18.3949 33.4524 18.3652C36.3451 18.2001 38.3471 17.8527 38.3471 17.4546Z" />
                  </svg>
                </PrismicLink>
              </div>
              <div className="col-6 text-end">
                <button id="mobile--nav_closer" onClick={closeNav}>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
              <nav>
                <ul className="nav flex-column nav--primary">
                  {menuDoc.data.menu_links.map((menuLink) => (
                    <MenuLink menuLink={menuLink} key={menuLink.link.id} />
                  ))}
                  {menuDoc.data.secondary_links.map((secLink) => (
                    <SecondaryLink secLink={secLink} key={secLink.link.id} />
                  ))}
                </ul>
              </nav>
              </div>
            </div>
          </div>
        </aside>
      </header>
    );
  }

  return null;
};
