import { CaseItem } from "./CaseItem";

// posts
//
export const CaseList = ({ casestudies }) => {
  return (
    <div id="case--study_list" className="row">
      {casestudies.map((case_study) => (
        <CaseItem case_study={case_study} key={case_study.id} />
      ))}
    </div>
  );
};
