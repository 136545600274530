import { SinglePerson } from "./SinglePerson";

// posts
//
export const PeopleList = ({ peoples }) => {
  return (
    <div id="team" className="row">
      {peoples.map((person_details) => (
        <SinglePerson person_details={person_details} key={person_details.id} />
      ))}
    </div>
  );
};
