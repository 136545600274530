import { useEffect } from "react";
import { useSinglePrismicDocument, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";
//import Masonry from 'react-masonry-css'

import { Layout } from "../components/Layout";
import { NotFound } from "./NotFound";

import { SuperSEO } from "react-super-seo";

// approach component
//
export const Approach = () => {
  const [approach, approachState] = useSinglePrismicDocument("approach");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound = approachState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (approachState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [approachState.state]);

  // masonry layout
  // const breakpointColumnsObj = {
  //   default: 3,
  //   1100: 3,
  //   700: 2,
  //   500: 1
  // };

  // Return the page if a document was retrieved from Prismic
  if (approach && menu) {
    return (
      <Layout wrapperClass="approach" menuDoc={menu}>
        <SuperSEO
          title={((approach.data.meta_title || {})[0] || {}).text}
          description={((approach.data.meta_description || {})[0] || {}).text}
        >
          <link
            rel="canonical"
            href={`https://launchsquad.com/approach`}
          
          />
          
          <meta
            itemprop="name"
            content={((approach.data.meta_title || {})[0] || {}).text}
          
          />
          <meta
            itemprop="description"
            content={((approach.data.meta_description || {})[0] || {}).text}
          
          />
          <meta
            itemprop="image"
            content={approach.data.meta_image.url}
          
          />

          <meta
            property="og:url"
            content="https://launchsquad.com/approach"
          
          />
          <meta 
            property="og:type"
            content="website"
          
          />
          <meta
            property="og:title"
            content={((approach.data.meta_title || {})[0] || {}).text}
          
          />
          <meta
            property="og:description"
            content={((approach.data.meta_description || {})[0] || {}).text}
          
          />
          <meta
            property="og:image"
            content={approach.data.meta_image.url}
          
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
          
          />
          <meta
            name="twitter:title"
            content={((approach.data.meta_title || {})[0] || {}).text}
          
          />
          <meta
            name="twitter:description"
            content={((approach.data.meta_description || {})[0] || {}).text}
          
          />
          <meta
            name="twitter:image"
            content={approach.data.meta_image.url}
          
          />
        </SuperSEO>

        <section className="lead--in bg--yellow">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="smono">
                  <PrismicText field={approach.data.title}></PrismicText>
                </h1>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-md-10">
                <div className="inline--images">
                  <PrismicRichText field={approach.data.summary} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="story bg--yellow">
          <hr />
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-md-4">
                <img className="img-fluid mb-5 mb-md-0" src={approach.data.story_image.url} alt={approach.data.story_image.alt} />
              </div>
              <div className="col-md-6 offset-md-2">
                <h3 className="smono mb-5">
                  <PrismicText field={approach.data.story_title}></PrismicText>
                </h3>
                <PrismicRichText field={approach.data.story_copy} />
              </div>
            </div>
            <div className="row align-items-center py-5">
              <div className="col-md-4 offset-md-2 order-md-2 pt-5">
                <img className="img-fluid" src={approach.data.how_image.url} alt={approach.data.how_image.alt} />
              </div>
              <div className="col-md-6 order-md-1 pt-5">
                <h3 className="smono mb-5">
                  <PrismicText field={approach.data.how_title}></PrismicText>
                </h3>
                <PrismicRichText field={approach.data.how_copy} />
              </div>
            </div>
          </div>
        </section>

        <section className="what-we-do pt-5">
          <div className="container">
            <div className="row mt-5">
              <div className="col">
                <h3 className="smono mb-5">
                  <PrismicText field={approach.data.what_we_do_header} />
                </h3>
              </div>
            </div>
            <div className="row">
                {approach.data.whate_we_do.map(item => {
                  return (
                    <div className="col-md-4 expertise--details" key={item.image.id}>
                      <figure
                        className="what--img"
                        style={{
                          backgroundImage: `url(${item.image.url})`,
                        }}      
                      ></figure>
                      <div className="expertise mt-3">
                        <h4
                          className="pt-2 pb-4 mt-4 mb-0"
                          style={{
                            backgroundImage: `url(${item.icon.url})`,
                          }}
                        >
                          <PrismicText field={item.heading} />
                        </h4>
                        <div className="text--sm">
                          <PrismicRichText field={item.copy} />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </section>

        <section className="working-with-us mt-5">
          <div className="container">
            <div className="row py-5">
              <div className="col pb-5">
                <h3 className="smono mb-5">
                  <PrismicText field={approach.data.work_title} />
                </h3>
                <div className="inline--images">
                  <PrismicRichText field={approach.data.work_copy} />
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
