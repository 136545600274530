import { useEffect } from "react";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { PostList } from "../components/PostList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// careers component
//
export const Careers = () => {
  const [careers, careersState] = useSinglePrismicDocument("careers");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {
    orderings: [{ field: "my.post.post_date", direction: "desc" }],
    pageSize: 3,
  });

  const notFound = careersState.state === "failed" || menuState.state === "failed" || blogPostsState.state === "failed";

  useEffect(() => {
    if (careersState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [careersState.state]);


  // get open positions from Lever
  function showJobs() {

    // set up the callback
    function ajax_get(url, callback) {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
          try {
          var data = JSON.parse(xmlhttp.responseText);
          } catch(err) {
          return;
          }
          callback(data);
        }
      };

      xmlhttp.open("GET", url, true);
      xmlhttp.send();
    }
   
    // grab post listings from Lever
    ajax_get('https://api.lever.co/v0/postings/launchsquad?mode=json', function(data) {

      // grab each open role array and parse
      data.forEach((role) => {
        var rolesHtml = '<hr/><div class="row open--role" onclick="window.open(\''+ role.hostedUrl + '\', \'_blank\');"><div class="col-md-6"><p>' + role.text + '</p></div><div class="col-md-4 text-md-end"><p>' + role.categories.location + '</p></div><div class="col-md-2 text-md-end"><p>' + role.categories.commitment + '</p></div></div>';
        // add roles to container
        document.getElementById('openRoles').innerHTML += rolesHtml;
      });

    });
  }

  // Return the page if a document was retrieved from Prismic
  if (careers && menu && blogPosts?.results) {
    const hasVideo = ((careers.data.vimeo_id || {})[0] || {}).text;
    return (
      <Layout wrapperClass="careers" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((careers.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((careers.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/careers`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((careers.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((careers.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={careers.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://www.launchsquad.com/careers"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((careers.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((careers.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={careers.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((careers.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((careers.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={careers.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="smono mb-5">
                  <PrismicText field={careers.data.title}></PrismicText>
                </h1>
                <div className="inline--images">
                  <PrismicRichText field={careers.data.summary} />
                </div>
              </div>
              <div className="col-md-4 offset-md-2">
                <img className="img-fluid position-absolute" src={careers.data.image.url} alt={careers.data.image.alt} />
              </div>
            </div>
          </div>
        </section>

        <section className="working-here">
          <hr />
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="smono mb-5">
                  <PrismicText field={careers.data.what_header} />
                </h3>
                <div className="inline--images sm">
                  <PrismicRichText field={careers.data.what_copy} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="locations py-5 bg--blue">
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <h3 className="smono mb-5">
                  <PrismicText field={careers.data.locations_header} />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="tab-content" id="pills-tabContent">
                  {careers.data.locations.map(item => {
                    return (
                      <div key={((item.location_identifier || {})[0] || {}).text} className={`tab-pane fade ${((item.locations_classes || {})[0] || {}).text}`} id={`pills-${((item.location_identifier || {})[0] || {}).text}`} role="tabpanel" aria-labelledby={`pills-${((item.location_identifier || {})[0] || {}).text}`} tabIndex="0">
                        <figure>
                          <img className="img-fluid" src={item.image1.url} alt={item.image1.alt} />
                          <figcaption
                            className="smono py-2"
                            style={{
                              backgroundImage: `url(${item.icon1.url})`,
                            }}
                          >
                            <PrismicText field={item.location_note}></PrismicText>
                          </figcaption>
                        </figure>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-md-5 offset-md-1">
                <ul className="nav nav-pills flex-column mb-5" id="locations-tab" role="tablist">
                  {careers.data.locations.map(item => {
                    return (
                      <li role="presentation" key={`${((item.location_identifier || {})[0] || {}).text}-2`} className="mb-2">
                        <button className={`nav-link h4 ${((item.locations_classes || {})[0] || {}).text}`} id={`pills-${((item.location_identifier || {})[0] || {}).text}`} data-bs-toggle="pill" data-bs-target={`#pills-${((item.location_identifier || {})[0] || {}).text}`} type="button" role="tab" aria-controls={`pills-${((item.location_identifier || {})[0] || {}).text}`} aria-selected="false">
                          <PrismicText field={item.location_title} />
                        </button>
                      </li>
                    )
                  })}
                </ul>
                <PrismicLink field={careers.data.locations_link} className="btn btn-secondary mt-3">
                  <PrismicText field={careers.data.locations_link_label} />
                </PrismicLink>
              </div>
            </div>
          </div>
        </section>

        {hasVideo?.length > 1 && (
        <section className="video bg--white">
          <div className="container">
            <div className="row">
              <div className="col">
                <h3 className="smono mb-5">
                  <PrismicText field={careers.data.video_header} />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="embed-container">
                  <iFrame src={`https://player.vimeo.com/video/${((careers.data.vimeo_id || {})[0] || {}).text}`} frameBorder="0"></iFrame>
                </div>
              </div>
            </div>
          </div>
        </section>
        )}

        <section className="openings bg--tan">
          <div className="container py-5">
            <div className="row">
              <div className="col">
                <h3 className="smono mb-5">
                  <PrismicText field={careers.data.roles_header} />
                </h3>
                <h2
                  className="has--bg-img mb-5"
                  style={{
                    backgroundImage: `url(${careers.data.roles_icon.url})`,
                  }}
                >
                  <PrismicText field={careers.data.roles_summary} />
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-11 offset-md-1">
                <div id="openRoles" className="mb-5">
                  {showJobs()}
                </div>
              </div>
            </div>
            <div className="row disclaimer">
              <div className="col-md-8 offset-md-1">
                <PrismicRichText field={careers.data.roles_disclaimer} />
              </div>
            </div>
          </div>
          <hr />
          <div className="container py-5">
            <div className="row py-5">
              <div className="col-md-10 offset-md-1">
                <PrismicRichText field={careers.data.roles_follow_up} />
              </div>
            </div>
          </div>
        </section>

        <section className="blog-spotlight">
          <div className="container py-5">
            <div className="row py-5">
              <div className="col-md-8">
                <h3 className="smono mb-5">
                  <PrismicText field={careers.data.blog_header} />
                </h3>
                <h4
                  className="sec--header"
                  style={{
                    backgroundImage: `url(${careers.data.blog_icon.url})`,
                  }}
                >
                  <PrismicText field={careers.data.blog_summary} />
                </h4>
              </div>
            </div>
            <div className="row">
              <PostList posts={blogPosts?.results} />
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
