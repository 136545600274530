import { PrismicRichText, PrismicText } from "@prismicio/react";

// measurement slice
//
export const Measurement = ({ slice }) => (
  <section className="content-slice component--measurement">
    <div className="container py-5">
      <div className="row py-5">
        <div className="col">
          <h3 className="smono mb-5">
            <PrismicText field={slice.primary.subheader1} />
          </h3>
          <h2
            className="has--bg-img"
            style={{
              backgroundImage: `url(${slice.primary.icon.url})`,
            }}
          >
            <PrismicText field={slice.primary.title1} />
          </h2>
        </div>
      </div>
      <div className="row">
        {slice.items.map(item => {
          return (
            <div 
              className="col metric my-5"
              key={item.metric_heading}
            >
              <span className="metric--heading">
                <PrismicText field={item.metric_heading} />
              </span>
              <span className="metric--copy">
                <PrismicText field={item.metric_copy} />
              </span>
            </div>
          )
        })}
      </div>
      <div className="row metric--points pt-5">
        <div className="col-md-8 offset-md-4">
          <PrismicRichText field={slice.primary.key_points} />
        </div>
      </div>
    </div>
  </section>
);