import { StudioItem } from "./StudioItem";

// posts
//
export const StudioList = ({ studiostudies }) => {
  return (
    <div id="studio--study_list" className="row">
      {studiostudies.map((studio_study) => (
        <StudioItem studio_study={studio_study} key={studio_study.id} />
      ))}
    </div>
  );
};
