import { PrismicText, PrismicLink } from "@prismicio/react";

/**
 * Site footer component
 */
export const Footer = ({ menuDoc }) => {
  if(menuDoc) {

    // social links
    const SocialLink = ({ socialLink }) => {
      return (
        <li className="nav-item">
          <PrismicLink className="nav-link ps-0 pe-4" field={socialLink.soc_link}>
            <img src={socialLink.icon.url} alt={socialLink.icon.alt} />
          </PrismicLink>
        </li>
      );
    };

    // Main nav comp
    const MenuLink = ({ menuLink }) => {
      return (
        <li className={`nav-link ${((menuLink.label || {})[0] || {}).text}`}>
          <PrismicLink field={menuLink.link} aria-label={((menuLink.label || {})[0] || {}).text}>
            <PrismicText field={menuLink.label} />
          </PrismicLink>
        </li>
      );
    };

    // Secondary nav comp
    const SecondaryLink = ({ secLink }) => {
      return (
        <li className={`nav-link ${((secLink.label || {})[0] || {}).text}`}>
          <PrismicLink field={secLink.link} aria-label={((secLink.label || {})[0] || {}).text}>
            <PrismicText field={secLink.label} />
          </PrismicLink>
        </li>
      );
    };

    // footer links
    const FooterLink  = ({ footerLink }) => {
      return (
        <li className="nav-link">
          <PrismicLink field={footerLink.link} aria-label={((footerLink.label || {})[0] || {}).text}>
            <PrismicText field={footerLink.label} />
          </PrismicLink>
        </li>
      );
    };

    return (
      <footer id="footer">
        <hr />
        <div className="container py-5">
          <div className="row pb-4">
            <div className="col-md-2">
              <nav>
                <ul className="nav nav--social">
                  {menuDoc.data.social_links.map((socialLink) => (
                    <SocialLink socialLink={socialLink} key={socialLink.icon.url} />
                  ))}
                </ul>
              </nav>
            </div>
            <div className="col-md-10">
              <nav>
                <ul className="nav nav--footer_all justify-content-end">
                  {menuDoc.data.menu_links.map((menuLink) => (
                    <MenuLink menuLink={menuLink} key={menuLink.link.id} />
                  ))}
                  {menuDoc.data.secondary_links.map((secLink) => (
                    <SecondaryLink secLink={secLink} key={secLink.link.id} />
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                className="img-fluid"
                src="/images/logo-launchsquad-white.svg"
                alt="LaunchSquad logo"
              />
            </div>
          </div>
          <div className="row copyright pt-5">
            <div className="col-md-4">
              <p>&copy; { new Date().getFullYear() } LaunchSquad.</p>
            </div>
            <div className="col-md-8">
              <nav>
                <ul className="nav nav--policies justify-content-end">
                  {menuDoc.data.footer_links.map((footerLink) => (
                    <FooterLink footerLink={footerLink} key={footerLink.link.id} />
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return null;
};
