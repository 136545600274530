import * as prismicH from "@prismicio/helpers";
import { useEffect } from "react";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { PeopleList } from "../components/PeopleList";
import { NotFound } from "./NotFound";

import { Helmet } from "react-helmet";

// about component
//
export const About = () => {
  const [about, aboutState] = useSinglePrismicDocument("about");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [people, peopleState] = usePrismicDocumentsByType("people", {
    orderings: [{ field: "my.people.name", direction: "asc" }],
    pageSize: 100,
  });

  const notFound = aboutState.state === "failed" || menuState.state === "failed" || peopleState.state === "failed";

  useEffect(() => {
    if (aboutState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [aboutState.state]);

  // values nav
  const ValNav =({ valuesNav }) => {
    return (
      <li role="presentation" className="mb-2">
        <button className={`nav-link h4 ${((valuesNav.value_classes || {})[0] || {}).text}`} id={`pills-${((valuesNav.value_title || {})[0] || {}).text}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${((valuesNav.value_title || {})[0] || {}).text}`} type="button" role="tab" aria-controls={`pills-${((valuesNav.value_title || {})[0] || {}).text}`} aria-selected="false">
          <PrismicText field={valuesNav.value_title} />
        </button>
      </li>
    );
  };

  // value imgs
  const ValImgs =({ valueImg }) => {
    return (
      <div className={`tab-pane fade ${((valueImg.value_classes || {})[0] || {}).text}`} id={`pills-${((valueImg.value_title || {})[0] || {}).text}`} role="tabpanel" aria-labelledby={`pills-${((valueImg.value_title || {})[0] || {}).text}-tab`} tabIndex="0">
        <figure>
          <img className="img-fluid" src={valueImg.value_image.url} alt={valueImg.value_image.alt} />
          <figcaption className="smono mt-3">
            <PrismicText field={valueImg.value_copy} />
          </figcaption>
        </figure>
      </div>
    );
  };

  // leadership
  const Leadership =({ leader }) => {
    return (
      <article className="col-md-4 person mb-5">
        <figure
          className="cover--photo mb-4"
          style={{ backgroundImage: `url(${leader.headshot.url})` }}
        />
        <h4 className="mt-3 mb-1">{((leader.name || {})[0] || {}).text}</h4>
        <h5 className="mb-3">{((leader.role || {})[0] || {}).text}</h5>
        <div className="bio">
          <PrismicRichText field={leader.bio1} />
        </div>
        {prismicH.asText(leader.location)?.length > 1 && (
        <h5 className="smono">
          <PrismicText field={leader.location} />
        </h5>
        )}
      </article>
    )
  }

  const loadLeadership = () => {
    let leaderCont = document.getElementById('leadership');
    leaderCont.querySelectorAll('.person').forEach(leader => {
      leader.classList.add('is--visible');
    });
    leaderCont.querySelectorAll('.load--more').forEach(leaderBtn => {
      leaderBtn.classList.add('d-none');
    })
  }

  const loadTeam = () => {
    let teamCont = document.getElementById('team');
    teamCont.querySelectorAll('.person').forEach(leader => {
      leader.classList.add('is--visible');
    });
    teamCont.querySelectorAll('.load--more').forEach(leaderBtn => {
      leaderBtn.classList.add('d-none');
    })
  }

  // randomize team members
  const shuffleTeam = () => {
    setTimeout(() => {
      let team = document.querySelector('#team');
      for (var i = team.children.length; i >= 0; i--) {
        team.appendChild(team.children[Math.random() * i | 0]);
      }
    }, 1000)
  }

  // Return the page if a document was retrieved from Prismic
  if (about && menu && people?.results) {
    const valuesHeader = about.data.values_header;
    return (
      <Layout wrapperClass="about" menuDoc={menu}>
        <Helmet>
        <title data-react-helmet="true">{((about.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((about.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.launchsquad.com/about`}
            data-react-helmet="true"
          />
          
          <meta
            itemprop="name"
            content={((about.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="description"
            content={((about.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            itemprop="image"
            content={about.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content="https://www.launchsquad.com/about"
            data-react-helmet="true"
          />
          <meta 
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={((about.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((about.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:image"
            content={about.data.meta_image.url}
            data-react-helmet="true"
          />

          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={((about.data.meta_title || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={((about.data.meta_description || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={about.data.meta_image.url}
            data-react-helmet="true"
          />
        </Helmet>

        <section className="lead--in bg--blue">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1 className="smono mb-5">
                  <PrismicText field={about.data.title}></PrismicText>
                </h1>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-md-8">
                <div className="inline--images mb-5">
                  <PrismicRichText field={about.data.summary} />
                </div>
                <div className="inline--images smm">
                  <PrismicRichText field={about.data.bio} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our--leadership">
          <div className="container py-5">
            <div className="row py-5">
              <div className="col-md-8 mb-5">
                <h3 className="smono mb-5">
                  <PrismicText field={about.data.leadership_header} />
                </h3>
                <div className="inline--images sm">
                  <PrismicRichText field={about.data.leadership_copy} />
                </div>
              </div>
              <div id="leadership" className="row pb-5">
                {about.data.leadership.map((leader) => (
                  <Leadership leader={leader} key={leader.headshot.id} />
                ))}
                <button className="btn btn-primary load--more" onClick={loadLeadership}>
                  View All Leadership
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="our--people bg--tan">
          <div className="container py-5">
            <div className="row py-5">
              <h3 className="smono mb-5">
                <PrismicText field={about.data.team_header} />
              </h3>
              <div className="row pb-5">
                <PeopleList peoples={people?.results} />
                <button className="btn btn-primary load--more" onClick={loadTeam}>
                  View All Team Members
                </button>
                {shuffleTeam()}
              </div>
            </div>
          </div>
        </section>
        
        {valuesHeader?.length > 1 && (
          <section className="our--values">
            <div className="container py-5">
              <div className="row py-5">
                <div className="col-md-6">
                  <ul className="nav nav-pills flex-column mb-3" id="values-tab" role="tablist">
                    {about.data.values.map((valuesNav) => (
                      <ValNav valuesNav={valuesNav} key={((valuesNav.value_title || {})[0] || {}).text} />
                    ))}
                  </ul>
                  <PrismicLink field={about.data.values_link} className="btn btn-primary mt-3">
                    <PrismicText field={about.data.values_link_label} />
                  </PrismicLink>
                </div>
                <div className="col-md-6">
                  <div className="tab-content" id="pills-tabContent" data-device="desktop">
                    {about.data.values.map((valueImg) => (
                      <ValImgs valueImg={valueImg} key={valueImg.value_image.url} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <section className="careers--blurb mt-5">
          <hr />
          <div className="container">
            <div className="row align-items-center py-5">
              <div className="col-md-5">
                <img className="img-fluid" src={about.data.careers_image.url} alt={about.data.careers_image.alt} />
              </div>
              <div className="col-md-6 offset-md-1">
                <h3 className="smono mb-5">
                  <PrismicText field={about.data.careers_header} />
                </h3>
                <PrismicRichText field={about.data.careers_copy} />
              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
