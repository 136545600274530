import { PrismicRichText, PrismicText } from "@prismicio/react";

// testimonials slice
//
export const Testimonials = ({ slice }) => (
  <section className="content-slice component--testimonial bg--tan py-5">
    <div className="container py-5">
      <div className="row py-5">
        <div className="col">
          <h2
            className="has--bg-img"
            style={{
              backgroundImage: `url(${slice.primary.icon.url})`,
            }}
          >
            <PrismicText field={slice.primary.heading} />
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 offset-md-4">
          <figure>
            <blockquote>
              <p>
                <PrismicText field={slice.primary.quote} />
              </p>
            </blockquote>
            <figcaption>
              <cite>
                <PrismicRichText field={slice.primary.cite} />
              </cite>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
  </section>
);