import { PrismicText, PrismicLink, PrismicRichText } from "@prismicio/react";

// homepage banner
//
export const HomepageBanner = ({ banner }) => (
  <section className="homepage--lead-in">
    <figure
      className="homepage-banner"
      style={{
        backgroundImage: `url(${banner.image.url})`,
      }}      
    >
      <img src={banner.logo.url} alt={banner.logo.alt} />
    </figure>
    <div className="container py-5">
      <div className="row py-5">
        <div className="col-md-10">
          <h3
            className="mb-3"
          >
            <PrismicText field={banner.title} />
            <img src={banner.icon.url} alt={banner.icon.alt} />
          </h3>
          <div className="inline--images my-5">
            <PrismicRichText field={banner.tagline} />
          </div>
          <PrismicLink field={banner.button_link} className="btn btn-primary mt-3">
            <PrismicText field={banner.button_label} />
          </PrismicLink>
        </div>
      </div>
    </div>
  </section>
);
