import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "launchsquad";

export const client = prismic.createClient(repositoryName, {
  // If your repo is private, add an access token.
  accessToken: "",

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  routes: [
    {
      type: "homepage",
      path: "/",
    },
    {
      type: "page",
      path: "/:uid",
    },
    {
      type: "approach",
      path: "/approach",
    },
    {
      type: "about",
      path: "/about",
    },
    {
      type: "careers",
      path: "/careers",
    },
    {
      type: "contact",
      path: "/contact",
    },
    {
      type: "work",
      path: "/work",
    },
    {
      type: "case_study",
      path: "/case/:uid",
    },
    {
      type: "blog_landing",
      path: "/blog",
    },
    {
      type: "post",
      path: "/blog-library/:uid",
    },
    {
      type: "content_studio",
      path: "/content-studio",
    },
    {
      type: "studio_case_study",
      path: "/content-studio/:uid",
    }
  ],
});
