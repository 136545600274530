import { FullWidthImage } from "./FullWidthImage";
import { ImageGallery } from "./ImageGallery";
import { ImageHighlight } from "./ImageHighlight";
import { Quote } from "./Quote";
import { TextSection } from "./TextSection";

import { Video } from "./Video";
import { SplitImageText } from "./SplitImageText";
import { Execution } from "./Execution";
import { Measurement } from "./Measurement";
import { Testimonials } from "./Testimonials";
import { WideImage } from "./WideImage";
import { BlockQuote } from "./BlockQuote";
import { TextBlock } from "./TextBlock";

export const components = {
  full_width_image: FullWidthImage,
  image_gallery: ImageGallery,
  image_highlight: ImageHighlight,
  quote: Quote,
  text_section: TextSection,
  video: Video,
  split_image_text: SplitImageText,
  execution: Execution,
  measurement: Measurement,
  testimonials: Testimonials,
  wide_image: WideImage,
  blockquote: BlockQuote,
  text_block: TextBlock,
};
